<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="edit" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            name="deptCd"
            label="관리부서"
            v-model="searchParam.deptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="grid"
          title="라인 목록"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="라인상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규라인" icon="add" @btnClicked="addParent" />
                <c-btn v-if="editable && dataeditable && selectedLineCd" label="LBLREMOVE" icon="remove" @btnClicked="removeLine" />
                <c-btn
                  v-if="editable && dataeditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="라인명"
                  name="lineName"
                  v-model="data.lineName">
                </c-text>
              </div>
              <div class="col-6">
                <c-dept
                  type="edit"
                  :editable="editable && dataeditable"
                  :required="true"
                  name="deptCd"
                  label="관리부서"
                  v-model="data.deptCd"
                ></c-dept>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable && dataeditable"
                  label="라인관리번호"
                  name="LineNo"
                  v-model="data.LineNo">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 사업장 -->
                <c-plant
                  :required="true"
                  :editable="editable && dataeditable"
                  label="LBLPLANT"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-3">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="LBLSORTORDER"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="라인 설명"
                  name="lineDesc"
                  :rows="2"
                  v-model="data.lineDesc">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'line-manage',
  data() {
    return {
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        deptCd: '',
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'center',
            sortable: false,
            style: 'width: 140px',
          },
          {
            name: 'lineName',
            field: 'lineName',
            label: '라인명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'orderNo',
            field: 'orderNo',
            // 순번
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.line.insert.url,
      saveType: 'POST',
      selectedLineCd: '',
      selectedLine: null,
      data: {
        plantCd: null,  // 사업장코드
        lineCd: '',  // 라인코드
        lineNo: '',  // 라인 관리번호
        deptCd: '',  // 관리부서
        lineName: '',  // 라인명
        lineDesc: '',  // 라인설명
        orderNo: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        delFlag: 'N',  // 삭제여부
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.line.list.url;
      this.detailUrl = selectConfig.mdm.line.get.url;
      this.checkUrl = selectConfig.mdm.line.check.url;
      this.insertUrl = transactionConfig.mdm.line.insert.url;
      this.updateUrl = transactionConfig.mdm.line.update.url;
      this.deleteUrl = transactionConfig.mdm.line.delete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      // 상세조회
      this.selectedLine = row;
      this.selectedLineCd = row.lineCd;
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.param = {lineCd: this.selectedLineCd};
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedLineCd = '';
      this.selectedLine = null;
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,  // 사업장코드
        lineCd: '',  // 라인코드
        lineNo: '',  // 라인 관리번호
        deptCd: '',  // 관리부서
        lineName: '',  // 라인명
        lineDesc: '',  // 라인설명
        orderNo: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        delFlag: 'N',  // 삭제여부
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeLine() {
      let msg = 'MSGREMOVE';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: msg, // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.lineCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getSearchList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSearchList();
    },
    addParent() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: null,  // 사업장코드
        lineCd: '',  // 라인코드
        lineNo: '',  // 라인 관리번호
        deptCd: '',  // 관리부서
        lineName: '',  // 라인명
        lineDesc: '',  // 라인설명
        orderNo: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        delFlag: 'N',  // 삭제여부
      };
    },
  }
};
</script>
